import { Organization } from '@ncr-voyix-commerce/react-common-components';

const EffectiveOrgExcemtpionList = ['customers', 'solution-partners', 'teams', 'internal-teams', 'commerce'];

const getOrgShortName = (orgName: string) => {
  const splitName = orgName.split('/');
  return splitName.length === 1 ? orgName : splitName.slice(-2)[0];
};

const formatOrganizations = (orgs: Organization[]): Organization[] => {
  const filtered = orgs.filter((o: Organization) => {
    const orgHierarchy: string[] = o.organizationName.split('/').filter((s) => !!s);
    return !(orgHierarchy.length === 1 && EffectiveOrgExcemtpionList.includes(orgHierarchy[0]));
  });

  for (let i = 0; i < filtered.length; i += 1) {
    filtered[i].organizationName = getOrgShortName(filtered[i].organizationName);
  }

  return filtered;
};

export default formatOrganizations;

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  buildTippedMinimumWageRuleConfig,
  getAllUpcomingRuleChangesetIds,
  getCurrentDateString,
  getJurisdictionRules,
  getSplitShiftRuleDescription,
  isTippedMinWageRule,
  organizeRuleConfigsByScope,
  getDefaultConfigsByRuleTypeAndJurisdictionPath,
  buildSchoolDayDefRuleConfig,
} from '../utils/utils';
import LaborRuleTemplate from '../LaborRuleTemplate/LaborRuleTemplate';
import {
  LaborRuleConfigData,
  LaborRule,
  LaborRuleGroup,
  RuleChangeReason,
  RuleFormValues,
  SchedulingRuleTypes,
  WagesRuleTypes,
  MinorRuleTypes,
  BreakRuleTypes,
  NewBreakRuleTypes,
} from '../models/LaborRuleConfiguration';
import JurisdictionSitesDataGrid, {
  JurisdictionSitesRow,
} from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import routes from '../../constants/routes';
import { RuleWithEffectiveDate } from '../RuleWithEffectiveDate/RuleWithEffectiveDate';
import DeactivateModal from '../modals/DeactivateModal/DeactivateModal';
import { ConfirmationFormValues } from '../modals/LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';
import { useDeleteUpcomingRuleConfigurations, useSaveNewRuleConfiguration } from '../requests/mutations';
import { handleSaveAndDelete } from '../LaborRuleEditView/utils/formSubmitHandlers';
import { useSnackbar } from '../../contexts/SnackbarContext';

const PROHIBITED_FROM_DEACTIVATION = [
  WagesRuleTypes.MinimumWageRule as string,
  WagesRuleTypes.TippedMinimumWageRule as string,
];
interface LaborRuleCardProps {
  title: string;
  ruleType: string;
  ruleGroup: LaborRuleGroup;
  ruleConfigs: LaborRuleConfigData[];
  sites: JurisdictionSitesRow[] | undefined;
  jurisdictionPath: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchConfigs: any;
  orgRuleConfigsLoading: boolean;
  defaultRuleConfigs: LaborRuleConfigData[];
  selectedJurisdiction?: string;
}

function LaborRuleCard(props: LaborRuleCardProps) {
  const { t } = useTranslation();
  const {
    title,
    ruleConfigs,
    sites,
    jurisdictionPath,
    ruleGroup,
    refetchConfigs,
    orgRuleConfigsLoading,
    defaultRuleConfigs,
    selectedJurisdiction,
  } = props;
  let { ruleType } = props;
  const history = useHistory();
  const tempRuleType = ruleType;
  const onEditRule = (stateOverrides?: Record<string, unknown>) => {
    if (ruleType === BreakRuleTypes.MissedBreakRule && stateOverrides) {
      history.push({
        pathname: `/settings/labor/labor-rules/${stateOverrides?.type}/edit`,
        state: { lastLocation: routes.LABOR_RULES, ...stateOverrides },
      });
    } else {
      history.push({
        pathname: `/settings/labor/labor-rules/${ruleType}/edit`,
        state: { lastLocation: routes.LABOR_RULES, ...stateOverrides },
      });
    }
  };
  if (ruleType === NewBreakRuleTypes.MealBreakRule || ruleType === NewBreakRuleTypes.RestBreakRule) {
    ruleType = BreakRuleTypes.MissedBreakRule;
  }

  const jurisdictionNormal = jurisdictionPath.split('.').at(1)?.replaceAll('_', ' ') || jurisdictionPath;
  const [checked, setChecked] = useState(true);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const saveNewRuleConfigurationMutation = useSaveNewRuleConfiguration();
  const deleteRuleConfigurationsMutation = useDeleteUpcomingRuleConfigurations();
  const { setSnackbarState } = useSnackbar();
  const theme = useTheme();

  const determineIfHasActiveConfigs = (_ruleType: string, activeJurisdictionRules: LaborRuleConfigData[]) => {
    if (!activeJurisdictionRules || activeJurisdictionRules.length === 0) return false;
    if (_ruleType === WagesRuleTypes.MinimumWageRule && isTippedMinWageRule(activeJurisdictionRules[0].rule)) {
      return activeJurisdictionRules.length > 1;
    }
    return activeJurisdictionRules.length > 0;
  };

  // If there are any rules that are split into two subtypes, this gets the subtype, while 'ruleType' is the true rule type
  const parsedRuleType = ruleType.split('.')[0];

  let laborRuleTemplates;
  // Group by scope and get jurisdiction only rules
  const jurisdictionRules = getJurisdictionRules(organizeRuleConfigsByScope(ruleConfigs), jurisdictionPath);

  // Get active jurisdiction rules
  const currentJurisdictionRules = jurisdictionRules.filter((x) => x.effectiveDate <= getCurrentDateString());
  const effectiveUntilDate = jurisdictionRules.filter((x) => x.effectiveDate > getCurrentDateString())?.[0]
    ?.effectiveDate;
  const currentDefaultRuleConfigs = getDefaultConfigsByRuleTypeAndJurisdictionPath(
    defaultRuleConfigs,
    ruleType,
    jurisdictionPath
  );

  const hasConfigs = determineIfHasActiveConfigs(ruleType, jurisdictionRules);
  useEffect(() => {
    setChecked(hasConfigs);
  }, [hasConfigs, ruleType]);

  const hasSummerVacationRule = jurisdictionRules.some(
    (ruleConfig) => ruleConfig?.rule.type === MinorRuleTypes.SummerVacationRule
  );

  const hasMinorSchoolDaysRule = jurisdictionRules.some(
    (ruleConfig) => ruleConfig?.rule.type === MinorRuleTypes.MinorSchoolDaysRule
  );

  if (
    currentJurisdictionRules.length > 1 &&
    parsedRuleType === WagesRuleTypes.MinimumWageRule &&
    isTippedMinWageRule(currentJurisdictionRules[0].rule)
  ) {
    const tippedConfig = buildTippedMinimumWageRuleConfig(jurisdictionRules);
    laborRuleTemplates = (
      <RuleWithEffectiveDate
        effectiveDate={tippedConfig.effectiveDate}
        effectiveUntil={effectiveUntilDate}
        currentConfigs={[tippedConfig]}
        ruleType={tippedConfig.rule.type}
        mainView
        currentDefaultRuleConfigs={currentDefaultRuleConfigs}
      />
    );
  } else if (
    currentJurisdictionRules.length > 1 &&
    parsedRuleType === MinorRuleTypes.MinorSchoolDayDefinitionRule &&
    hasSummerVacationRule &&
    hasMinorSchoolDaysRule
  ) {
    const schoolDayDefConfig = buildSchoolDayDefRuleConfig(jurisdictionRules);
    laborRuleTemplates = (
      <RuleWithEffectiveDate
        effectiveDate={schoolDayDefConfig.effectiveDate}
        effectiveUntil={effectiveUntilDate}
        currentConfigs={[schoolDayDefConfig]}
        ruleType={schoolDayDefConfig.rule.type}
        mainView
        currentDefaultRuleConfigs={currentDefaultRuleConfigs}
      />
    );
  } else if (hasConfigs && currentJurisdictionRules.length > 0) {
    const { effectiveDate, rule } = currentJurisdictionRules[0];
    laborRuleTemplates = (
      <RuleWithEffectiveDate
        effectiveDate={effectiveDate}
        effectiveUntil={effectiveUntilDate}
        currentConfigs={currentJurisdictionRules}
        ruleType={rule.type}
        mainView
        currentDefaultRuleConfigs={currentDefaultRuleConfigs}
        locality={selectedJurisdiction}
      />
    );
  } else {
    laborRuleTemplates = (
      <LaborRuleTemplate
        key='noGuidance'
        id={0}
        data-testid='labor-rule-template-noGuidance'
        sx={{
          mt: 2,
        }}
        rule={{} as LaborRule}
      />
    );
  }

  const ruleFormWithNoRules = (_jurisdictionPath: string): RuleFormValues => ({
    hierarchyPath: _jurisdictionPath,
    ruleType,
    rules: [],
    effectiveDate: getCurrentDateString(),
    changeReason: RuleChangeReason.COMPANY_POLICY,
    defaultRuleId: null,
  });

  const deactivateAction = (confirmData: ConfirmationFormValues) =>
    handleSaveAndDelete({
      data: ruleFormWithNoRules(jurisdictionPath),
      formData: confirmData,
      changesetsToDelete: getAllUpcomingRuleChangesetIds(ruleConfigs),
      handleSuccessProps: {
        ruleName: t(`laborRules.rules.${ruleType}.name`),
        effectiveDate: confirmData.effectiveDate,
        jurisdictionName: jurisdictionNormal,
        push: history.push,
        setOpenModal: setDeactivateModalOpen,
        refetchConfigs,
        setSnackbarState,
      },
      saveNewRuleConfigurationMutation,
      deleteRuleConfigurationsMutation,
    });

  return (
    <Box
      data-testid={`laborRuleCard-${title}`}
      sx={{ background: theme.palette.background.paper, padding: '1.5rem', marginTop: 2, borderRadius: 1 }}
    >
      {deactivateModalOpen && (
        <DeactivateModal
          ruleName={t(`laborRules.rules.${tempRuleType}.name`)}
          jurisdiction={jurisdictionPath.split('.')[1]}
          loading={
            saveNewRuleConfigurationMutation.isLoading ||
            deleteRuleConfigurationsMutation.isLoading ||
            orgRuleConfigsLoading
          }
          handleClose={() => {
            setChecked(true);
            setDeactivateModalOpen(false);
          }}
          handleDeactivate={deactivateAction}
        />
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {!PROHIBITED_FROM_DEACTIVATION.includes(ruleType) && (
            <FormControlLabel
              control={
                <Checkbox
                  data-testid={`${ruleType}-deactivate-checkbox`}
                  aria-label={t(`laborRules.dropdownAriaLabels.${checked ? 'ruleActivated' : 'ruleDeactivated'}`) || ''}
                  checked={checked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setChecked(e.target.checked);
                    if (hasConfigs) setDeactivateModalOpen(!e.target.checked);
                  }}
                  sx={{ pr: 0 }}
                />
              }
              label=''
            />
          )}
          <Typography variant='h6'>{title}</Typography>
        </Box>

        <Button
          disabled={!checked}
          onClick={() => onEditRule({ type: tempRuleType })}
          variant='contained'
          data-testid='edit-rule-button'
        >
          {t('laborRules.editLaborRuleText')}
        </Button>
      </Box>
      <Box sx={{ mt: '2rem' }}>
        {laborRuleTemplates}
        {ruleType === SchedulingRuleTypes.SplitShiftRule && <Box mt={3}>{getSplitShiftRuleDescription(t)}</Box>}
        {sites && (
          <Box data-tesid='jurisdiction-sites-table-container'>
            <Box sx={{ my: '1.5rem' }}>
              <Typography data-tesid='jurisdiction-sites-table-header' variant='subtitle1'>
                {t(`laborRules.sitesHeader`)}
              </Typography>
            </Box>
            <JurisdictionSitesDataGrid
              sites={sites}
              jurisdictionPath={jurisdictionPath}
              ruleGroup={ruleGroup}
              onEditRule={() => onEditRule({ type: tempRuleType })}
              allRules={ruleConfigs}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

LaborRuleCard.defaultProps = {
  selectedJurisdiction: 'United_States_of_America.Alabama',
};

export default LaborRuleCard;

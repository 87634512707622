import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum LaborRuleGroup {
  OVERTIME = 'OVERTIME',
  MINORS = 'MINORS',
  BREAKS = 'BREAKS',
  WAGES_AND_TIPS = 'WAGES_AND_TIPS',
  SCHEDULING = 'SCHEDULING',
}

// ====LABOR RULE GROUPINGS====
// These rules correspond to the properties in the translation files where their FE view name and rule template are stored
export enum OvertimeRuleTypes {
  WeeklyOvertimeRule = 'WeeklyOvertimeRule',
  DailyOvertimeRule = 'DailyOvertimeRule',
  WorkDayTypeRule = 'WorkDayTypeRule',
  ConsecutiveDayOvertimeRule = 'ConsecutiveDayOvertimeRule',
}

export enum MinorRuleTypes {
  MinorMaxDaysRule = 'MinorMaxDaysRule',
  MinorMaxHoursSchoolRule = 'MinorMaxHoursSchoolRule',

  // Minor prohibited hour in the backend is Minor permitted hour in the frontend
  MinorProhibitedHoursRule = 'MinorProhibitedHoursRule',
  SummerVacationRule = 'SummerVacationRule',
  ConsecutiveNonWorkNonSchoolRule = 'ConsecutiveNonWorkNonSchoolRule',
  MinorSchoolDaysRule = 'MinorSchoolDaysRule',
  MinorSchoolDayDefinitionRule = 'MinorSchoolDayDefinitionRule',
}

export enum BreakRuleTypes {
  MissedBreakRule = 'MissedBreakRule',
}

export enum NewBreakRuleTypes {
  RestBreakRule = 'RestBreakRule',
  MealBreakRule = 'MealBreakRule',
}

export enum BreakRuleNames {
  RestBreak = 'RestBreak',
  MealBreak = 'MealBreak',
}

export enum WagesRuleTypes {
  MinimumWageRule = 'MinimumWageRule',
  TippedMinimumWageRule = 'MinimumWageRule.Tipped',
}

export enum SchedulingRuleTypes {
  SplitShiftRule = 'SplitShiftRule',
  RightToRestRule = 'RightToRestRule',
  PredictabilityPayRule = 'PredictabilityPayRule',
  ExtraHoursPredictabilityPayRule = 'ExtraHoursPredictabilityPayRule',
  AdjustedRateRemainingTimePredictabilityPayRule = 'AdjustedRateRemainingTimePredictabilityPayRule',
  FixedFeePredictabilityPayRule = 'FixedFeePredictabilityPayRule',
  SchedulePublishRule = 'SchedulePublishRule',
}

export type RuleTypes =
  | OvertimeRuleTypes
  | MinorRuleTypes
  | BreakRuleTypes
  | WagesRuleTypes
  | SchedulingRuleTypes
  | NewBreakRuleTypes;

export const ruleTypesByGroup = {
  [LaborRuleGroup.OVERTIME]: Object.values(OvertimeRuleTypes),
  [LaborRuleGroup.MINORS]: Object.values(MinorRuleTypes),
  [LaborRuleGroup.BREAKS]: Object.values(BreakRuleTypes),
  [LaborRuleGroup.WAGES_AND_TIPS]: Object.values(WagesRuleTypes),
  [LaborRuleGroup.SCHEDULING]: Object.values(SchedulingRuleTypes),
};

export const newRuleTypesByGroup = {
  [LaborRuleGroup.OVERTIME]: Object.values(OvertimeRuleTypes),
  [LaborRuleGroup.MINORS]: Object.values(MinorRuleTypes),
  [LaborRuleGroup.BREAKS]: Object.values(NewBreakRuleTypes),
  [LaborRuleGroup.WAGES_AND_TIPS]: Object.values(WagesRuleTypes),
  [LaborRuleGroup.SCHEDULING]: Object.values(SchedulingRuleTypes),
};

export interface SiteRequestCriteria {
  criteria: {
    address: {
      city: string;
      country: string;
      postalCode: string;
      state: string;
      street: string;
    };
    contact: {
      contactPerson: string;
      phoneNumber: string;
      phoneNumberCountryCode: string;
    };
    coordinates: {
      latitude: number;
      longitude: number;
    };
    currency: string;
    customAttributeSets: [
      {
        attributes: [
          {
            key: string;
            value: string;
          }
        ];
        typeName: string;
      }
    ];
    description: string;
    enterpriseUnitName: string;
    group: string;
    isGrouped: boolean;
    locked: boolean;
    parentEnterpriseUnitId: string;
    siteName: string;
    status: string;
    timeZone: string;
  };
  customAttributes: string[];
  fields: string[];
  sort: [
    {
      column: string;
      direction: string;
    }
  ];
}
// ====LABOR RULE TYPES====
export interface BaseLaborRule {
  type: string;
  group: LaborRuleGroup;
}

export interface WeeklyOvertimeRule extends BaseLaborRule {
  threshold: number;
  rateAdjustment: number;
}

export interface DailyOvertimeRule extends BaseLaborRule {
  rateAdjustment: number;
  threshold: number;
}
export interface ConsecutiveDayOvertimeRule extends BaseLaborRule {
  rateAdjustment: number;
  threshold?: number;
  dayThreshold?: number;
  hourThreshold?: number;
}
export interface BreakRule extends BaseLaborRule {
  threshold: number;
  duration: number;
  makeupPayInHours: number;
  isPaid: boolean;
  group: LaborRuleGroup.BREAKS;
  // FE only value, delete before sending to BE
  isSubject?: boolean;
}

export interface MinorProhibitedHoursRule extends BaseLaborRule {
  minAge: number;
  maxAge: number;
  permittedStart: string;
  permittedEnd: string;
  isSummer: boolean;
  label: string;
  severity: string;
  // FE only value, delete before sending to BE
  schoolInSession?: boolean;
  start: string;
  end: string;
  schoolDays: string[];
}

export interface SplitShiftRule extends BaseLaborRule {
  splitThresholdSeconds: number;
  makeupHours: number;
  type: SchedulingRuleTypes.SplitShiftRule;
  group: LaborRuleGroup.SCHEDULING;
}

export enum RightToRestPaymentType {
  PayRateAdjustment = 'PayRateAdjustment',
  FixedAmount = 'FixedAmount',
}

export enum RightToRestTypePeriod {
  WholeShift = 'WHOLE_SHIFT',
  Overlap = 'OVERLAP',
}

export interface RightToRestRuleType {
  type: RightToRestPaymentType;
  rateAdjustment?: number;
  amount?: number;
  period?: RightToRestTypePeriod;
}

export interface RightToRestRule extends BaseLaborRule {
  paymentType: RightToRestRuleType;
  threshold: number;
}

enum PredPayChangeTypes {
  AdditionalHours = 'AdditionalHours',
  ChangeDateTime = 'ChangeDateTime',
}

export type ChangeType = {
  type: PredPayChangeTypes;
  threshold: number;
};

export enum CompensationTypes {
  RegularPayRate = 'RegularPayRate',
  ThresholdedPayRate = 'ThresholdedPayRate',
}

export enum Exemption {
  EMPLOYEE_REQUESTED = 'EMPLOYEE_REQUESTED',
  FORCE_MAJEURE = 'FORCE_MAJEURE',
  EMPLOYEE_VOLUNTARY_FILL_IN = 'EMPLOYEE_VOLUNTARY_FILL_IN',
  TERMINATED = 'TERMINATED',
  DISCIPLINARY_ACTION = 'DISCIPLINARY_ACTION',
  LARGE_EVENT_CHANGE = 'LARGE_EVENT_CHANGE',
  HOURS_GET_OVERTIME = 'HOURS_GET_OVERTIME',
  EMPLOYEE_REQUESTED_SHIFT_SWAP = 'EMPLOYEE_REQUESTED_SHIFT_SWAP',
}

export enum HoursChange {
  INCREASING = 'INCREASING',
  DECREASING = 'DECREASING',
  UNCHANGED = 'UNCHANGED',
}

export interface CompensationType {
  type: CompensationTypes;
  lowerLimit?: number;
  effectivePayRate?: number;
}

export interface ExtraHoursPredictabilityPayRule extends BaseLaborRule {
  type: SchedulingRuleTypes.ExtraHoursPredictabilityPayRule;
  hoursOfPay: number;
  hoursChange: HoursChange;
  compensationType: CompensationType;
  threshold: number;
  lowerLimit?: number;
  effectivePayRate?: number;
  effectiveRangeUpperBound: number;
  effectiveRangeLowerBound: number;
  exemptions: Exemption[];
  group: LaborRuleGroup.SCHEDULING;
}

export interface FixedFeePredictabilityPayRule extends BaseLaborRule {
  type: SchedulingRuleTypes.FixedFeePredictabilityPayRule;
  group: LaborRuleGroup.SCHEDULING;
  feeAmount: number;
  threshold: number;
  exemptions: Exemption[];
  hoursChange: HoursChange;
  effectiveRangeLowerBound: number;
  effectiveRangeUpperBound: number;
}
export interface AdjustedRateRemainingTimePredictabilityPayRule extends BaseLaborRule {
  type: SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule;
  group: LaborRuleGroup.SCHEDULING;
  compensationType: CompensationType;
  lowerLimit?: number;
  effectivePayRate?: number;
  rateAdjustment: number;
  threshold: number;
  exemptions: Exemption[];
  hoursChange: HoursChange;
  effectiveRangeLowerBound: number;
  effectiveRangeUpperBound: number;
}

export type PredictabilityPayRule =
  | ExtraHoursPredictabilityPayRule
  | FixedFeePredictabilityPayRule
  | AdjustedRateRemainingTimePredictabilityPayRule;

export interface MinimumWageRule extends BaseLaborRule {
  amount: number;
  isTipped: boolean;

  // it’s only for FE form and used only for 'Tipped minimum wage & tip credit calculation'
  stdMinWageAmount?: number;
}

export type LaborRule =
  | BaseLaborRule
  | WeeklyOvertimeRule
  | DailyOvertimeRule
  | ConsecutiveDayOvertimeRule
  | BreakRule
  | MinorProhibitedHoursRule
  | PredictabilityPayRule
  | RightToRestRule
  | MinimumWageRule;

// ====LABOR RULES SERVICE DATA TYPES====
export enum RuleChangeReason {
  COMPANY_POLICY = 'COMPANY_POLICY',
  LAW_CHANGED = 'LAW_CHANGED',
  CORRECTION = 'CORRECTION',
  INITIALIZATION = 'INITIALIZATION',
}

export enum ConfigurationTypes {
  LOCALITY = 'LOCALITY',
  STATE = 'STATE',
  CUSTOM = 'CUSTOM',
  FEDERAL = 'FEDERAL',
}

export interface LaborRuleConfigData {
  id: number;
  name: string;
  organizationId: string | null;
  path: string;
  hierarchyId: number;
  enterpriseUnitId: string | null;
  govRefLink: string | null;
  ruleName: string;
  rule: LaborRule;
  effectiveDate: string;
  defaultRuleId: number | null;
  changeReason: RuleChangeReason | null;
  changesetId: number;
}

export type BaseResponse = {
  lastPage: boolean;
  pageContent: JurisdictionSitesRow[];
  pageNumber: number;
  totalPages: number;
  totalResults: number;
};

export type EnterpriseGroupResponse = {
  category: string;
  description: string;
  displayName: string;
  enterpriseUnits: {
    enterpriseUnitId: string;
  }[];
  groupId: {
    groupName: string;
  };
};

// ====LABOR RULES FORM TYPES====
export interface CustomLaborRuleConfigForm {
  id?: number;
  rule: LaborRule;
}

export type RuleFormValues = {
  hierarchyPath: string;
  ruleType: string;
  rules: CustomLaborRuleConfigForm[];
  effectiveDate: string;
  changeReason: RuleChangeReason;
  defaultRuleId: number | null;
  siteId?: string;
};

export type CreateChangeSetRequest = Omit<RuleFormValues, 'rules'> & { rules: LaborRule[] };

export enum RuleTypographyInputType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  TIME = 'time',
  CURRENCY = 'currency',
}

export const NO_CONCURRENT_RULES: string[] = [
  WagesRuleTypes.MinimumWageRule,
  WagesRuleTypes.TippedMinimumWageRule,
  MinorRuleTypes.MinorSchoolDayDefinitionRule,
];

export const LaborRulePropertyTypeMappings: { [key: string]: RuleTypographyInputType } = {
  rateAdjustment: RuleTypographyInputType.NUMBER,
  threshold: RuleTypographyInputType.NUMBER,
  dayThreshold: RuleTypographyInputType.NUMBER,
  'paymentType.amount': RuleTypographyInputType.CURRENCY,
  duration: RuleTypographyInputType.NUMBER,
  makeupPayInHours: RuleTypographyInputType.NUMBER,
  splitThresholdSeconds: RuleTypographyInputType.NUMBER,
  makeupHours: RuleTypographyInputType.NUMBER,
  maxDaysPerWeek: RuleTypographyInputType.NUMBER,
  hourThreshold: RuleTypographyInputType.NUMBER,
  workDayType: RuleTypographyInputType.SELECT,
  isSummer: RuleTypographyInputType.SELECT,
  schoolInSession: RuleTypographyInputType.SELECT,
  isSubject: RuleTypographyInputType.SELECT,
  isPaid: RuleTypographyInputType.SELECT,
  period: RuleTypographyInputType.SELECT,
  days: RuleTypographyInputType.NUMBER,
  minAge: RuleTypographyInputType.SELECT,
  maxAge: RuleTypographyInputType.SELECT,
  permittedStart: RuleTypographyInputType.TIME,
  permittedEnd: RuleTypographyInputType.TIME,
  hoursChange: RuleTypographyInputType.SELECT,
  type: RuleTypographyInputType.SELECT,
  compensationType: RuleTypographyInputType.SELECT,
  feeAmount: RuleTypographyInputType.CURRENCY,
  amount: RuleTypographyInputType.CURRENCY,
  effectiveRangeLowerBound: RuleTypographyInputType.SELECT,
  effectiveRangeUpperBound: RuleTypographyInputType.SELECT,
  'paymentType.type': RuleTypographyInputType.SELECT,
  'paymentType.period': RuleTypographyInputType.SELECT,
  lowerLimit: RuleTypographyInputType.CURRENCY,
  effectivePayRate: RuleTypographyInputType.CURRENCY,
};

// these are the keys for the translation file
export const LaborRuleSelectValueKeys: { [x: string]: string[] } = {
  workDayType: ['WORK_WEEK_START', 'SHIFT_START'],
  isSummer: ['summer', 'notSummer'],
  isPaid: ['paid', 'unpaid'],
  isSubject: ['is', 'isNot'],
  schoolInSession: ['is', 'isNot'],
  period: ['daily', 'weekly'],
  hoursChange: [HoursChange.DECREASING, HoursChange.INCREASING, HoursChange.UNCHANGED],
  type: [
    SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
    SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
    SchedulingRuleTypes.FixedFeePredictabilityPayRule,
  ],
  compensationType: [CompensationTypes.RegularPayRate, CompensationTypes.ThresholdedPayRate],
  effectiveRangeUpperBound: ['1', '7', '14'],
  'paymentType.type': ['payRateAdjustment', 'fixedAmount'],
  'paymentType.period': ['OVERLAP', 'WHOLE_SHIFT'],
};

// these are all of the possible form values for the property keys above.
export const selectValueMap: { [x: string]: number | string } = {
  paid: 1,
  unpaid: 0,
  is: 1,
  isNot: 0,
  summer: 1,
  notSummer: 0,
  session: 1,
  notSession: 0,
  WORK_WEEK_START: 'WORK_WEEK_START',
  SHIFT_START: 'SHIFT_START',
  daily: 'DAILY',
  weekly: 'WEEKLY',
  [HoursChange.DECREASING]: HoursChange.DECREASING,
  [HoursChange.INCREASING]: HoursChange.INCREASING,
  [HoursChange.UNCHANGED]: HoursChange.UNCHANGED,
  [SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule]:
    SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
  [SchedulingRuleTypes.ExtraHoursPredictabilityPayRule]: SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
  [SchedulingRuleTypes.FixedFeePredictabilityPayRule]: SchedulingRuleTypes.FixedFeePredictabilityPayRule,
  [CompensationTypes.RegularPayRate]: CompensationTypes.RegularPayRate,
  [CompensationTypes.ThresholdedPayRate]: CompensationTypes.ThresholdedPayRate,
  '1': 1 * 86400,
  '7': 7 * 86400,
  '14': 14 * 86400,
  payRateAdjustment: 'PayRateAdjustment',
  fixedAmount: 'FixedAmount',
  OVERLAP: 'OVERLAP',
  WHOLE_SHIFT: 'WHOLE_SHIFT',
};

export const ageRange: { [x: string]: number } = {
  minAge: 14,
  maxAge: 20,
};

export const statesWithGrouping = [
  'United_States_of_America.California',
  'United_States_of_America.Pennsylvania',
  'United_States_of_America.New_York',
  'United_States_of_America.Illinois',
  'United_States_of_America.Washington',
];

export const rightToRestWithPeriodLocalities = ['Chicago'];

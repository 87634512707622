import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalHeaders, Req, Res } from '@cbo/shared-library';
import { CreateSiteTagRequest } from '@cbo/shared-library/request/site-management.request';
import {
  BulkDeleteData,
  EnterpriseUnitGroupViewData,
  PagedResponse,
  SitesAssignedToTagsResponse,
} from '@cbo/shared-library/response/site-management.response';
import useCallBsl from '../../utils/hooks/useCallBsl';
import {
  addNewLink,
  deleteCompanyLink as deleteCompanyLinkServiceFunction,
  editLink,
  getAllLinks,
} from '../../services/LinkService';
import toQueryParams from '../../utils/objectUtils/toQueryParams';
import { proxies, useAdminRequests as legacyUseAdminRequests } from '../requests';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { MessageResponse } from '../models/MessageResponse';

const Verb = Req.Firebase.HttpVerb;

export interface GetAllGlMappingsRequest {
  dataTypes?: string[];
  entityNames?: string[];
  glAccountNames?: string[];
}

export interface GlMappingsResponse {
  id?: number;
  dataType: string;
  entityName: string;
  glAccountName?: string;
  entityId: string;
  glAccountId?: string;
}

export interface GlAccountId {
  glAccountId: string;
}

export interface CreateUpdateGlMappingRequest extends GlAccountId {
  jobCodeDefinitionId: string;
}

export interface JobCodeMappingId {
  id: number;
}

export interface GlMappingFilterResponse {
  dataTypes: string[];
  entityNames: string[];
  glAccountNames: string[];
}

export interface TotalUnreadNotifications {
  totalUnread: number;
}

export interface NotificationDetails extends TotalUnreadNotifications {
  data: Res.Notifications.Notification[];
  totalPages: number;
  totalResults: number;
}
export interface NotificationResponse extends NotificationDetails {
  lastPage: boolean;
  pageNumber: number;
}

export type AdminRequests = {
  getUserPreferencesData: () => Promise<Res.Admin.UserPreferences>;
  getUserDashboardConfigurationData: () => Promise<Res.Admin.DashboardConfigurationResponse>;
  updateUserPreferences: (prefs: Req.Admin.UserPreferences) => Promise<Res.Admin.UserPreferences>;
  getAllCompanyLinks: (filterByUserRoles?: boolean) => Promise<Res.Firebase.Link[]>;
  addNewCompanyLink: (link: Req.Firebase.LinkData) => Promise<Res.Firebase.Link>;
  editCompanyLink: (link: Req.Firebase.Link) => Promise<Res.Firebase.Link>;
  deleteCompanyLink: (linkId: string) => Promise<string>;
  getAllNotificationSettingsData: () => Promise<Res.Notifications.NotificationSettings[]>;
  getNotificationSettingsData: (id: string) => Promise<Res.Notifications.NotificationSettings>;
  getAllEventTypesData: () => Promise<{ data: Res.Notifications.EventType[] | undefined }>;
  deleteNotificationSettingsData: (notificationSettingId: string) => Promise<{ rowsAffected: number }>;
  createNotificationSettingsData: (
    notificationSetting: Req.Notifications.NotificationSettingsData
  ) => Promise<Res.Notifications.NotificationSettings>;
  updateNotificationSettingsData: (
    id: string,
    notificationSetting: Req.Notifications.NotificationSettingsData
  ) => Promise<Res.Notifications.NotificationSettings>;
  getFiscalCalendarData: () => Promise<Res.Calendar.FiscalCalendar>;
  createFiscalCalendar: (fiscalCalendar: Req.Calendar.FiscalCalendarData) => Promise<Res.Calendar.FiscalCalendar>;
  updateFiscalCalendar: (
    id: string,
    fiscalCalendar: Req.Calendar.FiscalCalendarData
  ) => Promise<Res.Calendar.FiscalCalendar>;
  getFiscalCalendarPreviewData: (year: number) => Promise<Res.Calendar.FiscalCalendarPreview>;
  getPayrollCalendarData: (siteId: string) => Promise<Res.Calendar.PayrollCalendar | MessageResponse>;
  upsertPayrollCalendar: (payrollCalendar: Req.Calendar.PayrollCalendarData) => Promise<Res.Calendar.PayrollCalendar>;
  getPayrollCalendarFilters: (siteId: string) => Promise<Res.Calendar.PayrollCalendarFilters>;
  getAllSitesData: (siteIds?: string[]) => Promise<Res.SiteManagement.PagedResponse<Res.SiteManagement.SiteViewData>>;
  getSiteDetailsDataById: (id: string) => Promise<Res.SiteManagement.SiteViewData>;
  getSiteDetailsDataByIds: (ids: string[]) => Promise<Res.SiteManagement.SiteViewData[]>;
  getAllSiteGroupsData: (
    page: number,
    limit: number,
    searchString?: string
  ) => Promise<PagedResponse<Res.SiteManagement.EnterpriseUnitGroupViewData>>;
  getSiteGroupData: (groupName: string | undefined) => Promise<EnterpriseUnitGroupViewData | null>;
  getEuDataByGroupName: (groupName: string) => Promise<Res.SiteManagement.EnterpriseUnitData[]>;
  createSiteGroupData: (newSiteData: Req.SiteManagement.SiteGroupData) => Promise<Res.SiteManagement.SiteGroupData>;
  deleteSiteGroupData: (ids: string[]) => Promise<BulkDeleteData>;
  updateSiteGroupData: (id: string, displayName: string) => Promise<void>;
  updateSiteGroupSites: (id: string, siteIds: string[]) => Promise<void>;
  updateSiteDayParts: (
    siteDayParts: Req.SiteManagement.DayPart[],
    enterpriseUnitId: string
  ) => Promise<Res.SiteManagement.SiteViewData>;
  getAllSiteTagData: () => Promise<Res.SiteManagement.SiteTag[]>;
  getSitesAssignedToTags: (tagIds: string[]) => Promise<SitesAssignedToTagsResponse>;
  getAllAuditLogData: (payload: Req.AuditLog.FilterRequest) => Promise<Res.AuditLog.LogEntry>;
  getAllAuditLogFilters: () => Promise<Res.AuditLog.FilterResponse>;
  assignSiteTags: (
    assignSiteTags: Req.SiteManagement.AssignSitesRequest
  ) => Promise<Res.SiteManagement.AssignSitesResponse>;
  removeSiteTags: (
    removeSitesRequest: Req.SiteManagement.RemoveSitesRequest
  ) => Promise<Res.SiteManagement.RemoveSitesResponse>;
  createSiteTagData: (siteTag: CreateSiteTagRequest) => Promise<void>;
  updateSiteTagData: (id: string, siteTag: CreateSiteTagRequest) => Promise<void>;
  deleteSiteTagData: (ids: string[]) => Promise<BulkDeleteData>;
  getSiteTagDetailsDataById: (siteTagId: string) => Promise<Res.SiteManagement.SiteTag>;
  getEnterpriseUnits: (page: number, limit: number) => Promise<PagedResponse<Res.SiteManagement.EnterpriseUnitData>>;
  getNotificationsData: (pageNumber: number, pageSize: number) => Promise<NotificationResponse>;
  getTotalUnreadNotifications: () => Promise<TotalUnreadNotifications>;
  markAllNotificationAsRead: () => Promise<Res.Notifications.Notification>;
  getAllJobCodeMappings: (body: GetAllGlMappingsRequest) => Promise<GlMappingsResponse[] | undefined>;
  updateGlMapping: (id: string, body: CreateUpdateGlMappingRequest) => Promise<JobCodeMappingId | undefined>;
  createGlMapping: (body: CreateUpdateGlMappingRequest) => Promise<JobCodeMappingId | undefined>;
  getGlMappingFilters: () => Promise<GlMappingFilterResponse | undefined>;
  deleteGlMapping: (id: string) => Promise<JobCodeMappingId | undefined>;
  getAllGlMappings: (body: GetAllGlMappingsRequest) => Promise<GlMappingsResponse | void>;
};

export const useAdminRequests = (): AdminRequests => {
  const callBsl = useCallBsl();
  const { setSnackbarState } = useSnackbar();
  const { t } = useTranslation();

  const { createSiteGroup } = legacyUseAdminRequests();
  /**
   * USER PREFERENCES
   */
  const getUserPreferencesData = async (): Promise<Res.Admin.UserPreferences> =>
    callBsl({
      proxy: proxies.admin,
      verb: Verb.GET,
      pathSegments: ['users', 'preferences'],
    });

  const getUserDashboardConfigurationData = async (): Promise<Res.Admin.DashboardConfigurationResponse> =>
    callBsl({
      proxy: proxies.admin,
      verb: Verb.GET,
      pathSegments: ['dashboard-config'],
    });

  const updateUserPreferences = async (prefs: Req.Admin.UserPreferences): Promise<Res.Admin.UserPreferences> =>
    callBsl({
      proxy: proxies.admin,
      verb: Verb.POST,
      pathSegments: ['users', 'preferences'],
      payload: prefs,
    });

  /**
   * COMPANY LINKS
   */
  const getAllCompanyLinks = async (filterByUserRoles?: boolean): Promise<Res.Firebase.Link[]> =>
    getAllLinks(filterByUserRoles);

  const addNewCompanyLink = async (link: Req.Firebase.LinkData): Promise<Res.Firebase.Link> => addNewLink(link);

  const editCompanyLink = async (link: Req.Firebase.Link): Promise<Res.Firebase.Link> => editLink(link);

  const deleteCompanyLink = async (linkId: string): Promise<string> => deleteCompanyLinkServiceFunction(linkId);

  /**
   * NOTIFICATIONS
   */
  const getAllNotificationSettingsData = async (): Promise<Res.Notifications.NotificationSettings[]> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.GET,
      pathSegments: ['settings'],
    });

  const getNotificationsData = async (pageNumber: number, pageSize: number): Promise<NotificationResponse> =>
    callBsl<NotificationResponse>({
      proxy: proxies.notifications,
      verb: Req.Firebase.HttpVerb.GET,
      pathSegments: ['notifications'],
      queryParams: toQueryParams({
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
      }),
    });

  const getTotalUnreadNotifications = async (): Promise<TotalUnreadNotifications> =>
    callBsl<TotalUnreadNotifications>({
      proxy: proxies.notifications,
      verb: Req.Firebase.HttpVerb.GET,
      pathSegments: ['notifications', 'total-unread'],
    });

  const markAllNotificationAsRead = async (): Promise<Res.Notifications.Notification> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.PUT,
      pathSegments: ['notifications', 'mark-all-as-read'],
    });

  const getNotificationSettingsData = async (id: string): Promise<Res.Notifications.NotificationSettings> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.GET,
      pathSegments: ['settings', id],
      queryParams: toQueryParams({ includeEventTypes: 'true' }),
    });

  const getAllEventTypesData = async (): Promise<{ data: Res.Notifications.EventType[] }> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.GET,
      pathSegments: ['event-types'],
    });

  const createNotificationSettingsData = async (
    settings: Req.Notifications.NotificationSettingsData
  ): Promise<Res.Notifications.NotificationSettings> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.POST,
      pathSegments: ['settings'],
      payload: settings,
    });

  const updateNotificationSettingsData = async (
    id: string,
    settings: Req.Notifications.NotificationSettingsData
  ): Promise<Res.Notifications.NotificationSettings> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.PUT,
      pathSegments: ['settings', id],
      payload: settings,
    });

  const deleteNotificationSettingsData = async (id: string): Promise<{ rowsAffected: number }> =>
    callBsl({
      proxy: proxies.notifications,
      verb: Verb.DELETE,
      pathSegments: ['settings', id],
    });

  /**
   * FISCAL CALENDAR
   */
  const getFiscalCalendarData = async (): Promise<Res.Calendar.FiscalCalendar> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.GET,
      pathSegments: ['fiscal-calendar'],
    });

  const createFiscalCalendar = async (
    fiscalCalendar: Req.Calendar.FiscalCalendarData
  ): Promise<Res.Calendar.FiscalCalendar> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.POST,
      pathSegments: ['fiscal-calendar'],
      payload: fiscalCalendar,
    });

  const updateFiscalCalendar = async (
    id: string,
    fiscalCalendar: Req.Calendar.FiscalCalendarData
  ): Promise<Res.Calendar.FiscalCalendar> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.PUT,
      pathSegments: ['fiscal-calendar', id],
      payload: fiscalCalendar,
    });

  const getFiscalCalendarPreviewData = async (year: number): Promise<Res.Calendar.FiscalCalendarPreview> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.GET,
      pathSegments: ['fiscal-calendar', 'preview'],
      queryParams: toQueryParams({ year: year.toString() }),
    });

  const getPayrollCalendarData = async (siteId: string): Promise<Res.Calendar.PayrollCalendar | MessageResponse> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.GET,
      pathSegments: ['payroll-calendars', 'find'],
      queryParams: toQueryParams({ siteId }),
    });

  const upsertPayrollCalendar = async (
    payrollCalendar: Req.Calendar.PayrollCalendarData
  ): Promise<Res.Calendar.PayrollCalendar> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.POST,
      pathSegments: ['payroll-calendars'],
      payload: payrollCalendar,
      enterpriseUnit: payrollCalendar.siteIds[0],
    });

  const getPayrollCalendarFilters = async (siteId: string): Promise<Res.Calendar.PayrollCalendarFilters> =>
    callBsl({
      proxy: proxies.calendar,
      verb: Verb.GET,
      pathSegments: ['payroll-calendars', 'filters'],
      queryParams: toQueryParams({ siteId }),
    });

  /**
   * Site Management
   */
  const getAllSitesData = async (
    siteIds?: string[]
  ): Promise<Res.SiteManagement.PagedResponse<Res.SiteManagement.SiteViewData>> => {
    let additionalHeaders;
    if (siteIds) {
      additionalHeaders = siteIds.map((siteId) => ({
        key: AdditionalHeaders.SITE_ID,
        value: siteId,
      }));
    }
    return callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['sites'],
      additionalHeaders,
    });
  };

  const getSiteDetailsDataById = async (siteId: string): Promise<Res.SiteManagement.SiteViewData> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['sites', siteId],
    });

  const getSiteDetailsDataByIds = async (siteIds: string[]): Promise<Res.SiteManagement.SiteViewData[]> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.POST,
      pathSegments: ['sites', 'find-by-ids'],
      payload: siteIds,
    });

  const createSiteGroupData = async (
    group: Req.SiteManagement.SiteGroupData
  ): Promise<Res.SiteManagement.SiteGroupData> => createSiteGroup(group);

  const getAllSiteGroupsData = async (
    page: number,
    limit: number,
    searchString?: string
  ): Promise<PagedResponse<Res.SiteManagement.EnterpriseUnitGroupViewData>> => {
    const queryParams: Req.Firebase.SingleQueryParam[] = [
      { kind: 'single', key: 'page', value: page.toString() },
      { kind: 'single', key: 'limit', value: limit.toString() },
    ];
    if (searchString) {
      queryParams.push({ kind: 'single', key: 'filterByKeyword', value: searchString.toString() });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await callBsl<any>({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['site-groups'],
      queryParams,
    });

    return result;
  };

  const getSiteGroupData = async (groupNameId: string | undefined): Promise<EnterpriseUnitGroupViewData | null> => {
    if (!groupNameId) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await callBsl<any>({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['site-groups', groupNameId],
    });

    return result;
  };

  const getEuDataByGroupName = async (groupName: string): Promise<Res.SiteManagement.EnterpriseUnitData[]> =>
    callBsl<Res.SiteManagement.EnterpriseUnitData[]>({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['site-groups', 'enterprise-units'],
      queryParams: [{ kind: 'single', key: 'groupName', value: groupName }],
    });

  const deleteSiteGroupData = async (ids: string[]): Promise<BulkDeleteData> => {
    const params: Array<Req.Firebase.SingleQueryParam | Req.Firebase.MultiQueryParam> = [];
    params.push({ kind: 'multi', key: 'ids[]', value: ids });
    return callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.DELETE,
      pathSegments: ['site-groups'],
      queryParams: params,
    });
  };

  const updateSiteGroupData = async (id: string, displayName: string): Promise<void> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.PUT,
      pathSegments: ['site-groups', id],
      payload: {
        name: displayName,
      },
    });

  const updateSiteGroupSites = async (id: string, siteIds: string[]): Promise<void> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.PUT,
      pathSegments: ['site-groups', id, 'sites'],
      payload: {
        siteIds,
      },
    });

  const updateSiteDayParts = async (
    siteDayParts: Req.SiteManagement.DayPart[],
    enterpriseUnitId: string
  ): Promise<Res.SiteManagement.SiteViewData> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.PATCH,
      enterpriseUnit: enterpriseUnitId,
      pathSegments: ['sites', 'day-parts'],
      payload: { dayparts: siteDayParts },
    });

  /**
   * Site Tag
   */
  const getAllSiteTagData = (): Promise<Res.SiteManagement.SiteTag[]> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['site-tags'],
    });

  const getSitesAssignedToTags = async (tagIds: string[]): Promise<SitesAssignedToTagsResponse> => {
    const params: Array<Req.Firebase.SingleQueryParam | Req.Firebase.MultiQueryParam> = [];
    params.push({ kind: 'multi', key: 'ids[]', value: tagIds });

    return callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['site-tags', 'sites-assigned'],
      queryParams: params,
    });
  };

  const assignSiteTags = (
    assignSitesRequest: Req.SiteManagement.AssignSitesRequest
  ): Promise<Res.SiteManagement.AssignSitesResponse> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.PUT,
      pathSegments: ['site-tags', 'assign-sites'],
      payload: { ...assignSitesRequest },
    });

  const removeSiteTags = (
    removeSitesRequest: Req.SiteManagement.RemoveSitesRequest
  ): Promise<Res.SiteManagement.RemoveSitesResponse> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.PUT,
      pathSegments: ['site-tags', 'remove-sites'],
      payload: { ...removeSitesRequest },
    });

  const createSiteTagData = async (siteTag: CreateSiteTagRequest): Promise<void> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.POST,
      pathSegments: ['site-tags'],
      payload: siteTag,
    });

  const updateSiteTagData = async (id: string, siteTag: CreateSiteTagRequest): Promise<void> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.PUT,
      pathSegments: ['site-tags', id],
      payload: siteTag,
    });

  const deleteSiteTagData = async (ids: string[]): Promise<BulkDeleteData> => {
    const params: Array<Req.Firebase.SingleQueryParam | Req.Firebase.MultiQueryParam> = [];
    params.push({ kind: 'multi', key: 'ids[]', value: ids });

    return callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.DELETE,
      pathSegments: ['site-tags'],
      queryParams: params,
    });
  };

  const getSiteTagDetailsDataById = async (siteTagId: string): Promise<Res.SiteManagement.SiteTag> =>
    callBsl({
      proxy: proxies.siteManagement,
      verb: Verb.GET,
      pathSegments: ['site-tags', siteTagId],
    });

  const getEnterpriseUnits = async (
    page: number,
    limit: number
  ): Promise<PagedResponse<Res.SiteManagement.EnterpriseUnitData>> => {
    const queryParams: Req.Firebase.SingleQueryParam[] = [
      { kind: 'single', key: 'page', value: page.toString() },
      { kind: 'single', key: 'limit', value: limit.toString() },
    ];

    return callBsl({
      proxy: 'provisioning.enterprise-units',
      verb: Verb.GET,
      pathSegments: [],
      queryParams,
    });
  };

  /**
   * Audit Log
   */
  const getAllAuditLogData = async (payload: Req.AuditLog.FilterRequest): Promise<Res.AuditLog.LogEntry> =>
    callBsl({
      proxy: proxies.admin,
      verb: Verb.POST,
      pathSegments: ['audit-log'],
      payload,
    });

  const getAllAuditLogFilters = async (): Promise<Res.AuditLog.FilterResponse> =>
    callBsl({
      proxy: proxies.admin,
      verb: Verb.GET,
      pathSegments: ['audit-log', 'filters'],
    });

  /**
   * GL mapping
   */

  // helper method to get query params for GL mappings
  const getGlMappingsParams = (
    body: GetAllGlMappingsRequest
  ): Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> => {
    const queryParams: Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> = [
      { kind: 'multi', key: 'dataTypes', value: body.dataTypes && body.dataTypes.length > 0 ? body.dataTypes : [] },
      {
        kind: 'multi',
        key: 'entityNames',
        value: body.entityNames && body.entityNames.length > 0 ? body.entityNames : [],
      },
      {
        kind: 'multi',
        key: 'glAccountNames',
        value: body.glAccountNames && body.glAccountNames.length > 0 ? body.glAccountNames : [],
      },
    ];
    return queryParams;
  };

  const getAllJobCodeMappings = useCallback(
    async (body: GetAllGlMappingsRequest) =>
      callBsl<GlMappingsResponse[]>({
        proxy: proxies.glAccounts,
        verb: Verb.GET,
        pathSegments: ['job-code-definition-mapping'],
        queryParams: body ? getGlMappingsParams(body) : [],
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  const getGlMappingFilters = useCallback(
    async () =>
      callBsl<GlMappingFilterResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.GET,
        pathSegments: ['job-code-definition-mapping', 'filters'],
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  const updateGlMapping = useCallback(
    async (id: string, body: CreateUpdateGlMappingRequest) =>
      callBsl<JobCodeMappingId>({
        proxy: proxies.glAccounts,
        verb: Verb.PUT,
        pathSegments: ['job-code-definition-mapping', id],
        payload: body,
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  const createGlMapping = useCallback(
    async (body: CreateUpdateGlMappingRequest) =>
      callBsl<JobCodeMappingId>({
        proxy: proxies.glAccounts,
        verb: Verb.POST,
        pathSegments: ['job-code-definition-mapping'],
        payload: body,
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  const deleteGlMapping = useCallback(
    async (id: string) =>
      callBsl<JobCodeMappingId>({
        proxy: proxies.glAccounts,
        verb: Verb.DELETE,
        pathSegments: ['job-code-definition-mapping', id],
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  const getAllGlMappings = useCallback(
    async (body: GetAllGlMappingsRequest) =>
      callBsl<GlMappingsResponse>({
        verb: Verb.GET,
        proxy: proxies.glAccounts,
        pathSegments: ['gl-mapping'],
        queryParams: body ? getGlMappingsParams(body) : [],
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  return {
    getUserPreferencesData,
    getUserDashboardConfigurationData,
    updateUserPreferences,
    getAllCompanyLinks,
    addNewCompanyLink,
    editCompanyLink,
    deleteCompanyLink,
    getAllNotificationSettingsData,
    getNotificationSettingsData,
    getAllEventTypesData,
    deleteNotificationSettingsData,
    createNotificationSettingsData,
    updateNotificationSettingsData,
    getFiscalCalendarData,
    createFiscalCalendar,
    updateFiscalCalendar,
    getFiscalCalendarPreviewData,
    getPayrollCalendarData,
    upsertPayrollCalendar,
    getPayrollCalendarFilters,
    getAllSitesData,
    getSiteDetailsDataById,
    createSiteGroupData,
    getAllSiteGroupsData,
    getSiteGroupData,
    getEuDataByGroupName,
    deleteSiteGroupData,
    updateSiteGroupData,
    updateSiteGroupSites,
    getSiteDetailsDataByIds,
    updateSiteDayParts,
    getAllSiteTagData,
    getSitesAssignedToTags,
    getAllAuditLogData,
    getAllAuditLogFilters,
    assignSiteTags,
    removeSiteTags,
    createSiteTagData,
    updateSiteTagData,
    deleteSiteTagData,
    getSiteTagDetailsDataById,
    getEnterpriseUnits,
    getNotificationsData,
    getTotalUnreadNotifications,
    markAllNotificationAsRead,
    getAllJobCodeMappings,
    updateGlMapping,
    createGlMapping,
    getGlMappingFilters,
    deleteGlMapping,
    getAllGlMappings,
  };
};
